import ButtonClick from "./components/ButtonClick";
import ICP from "./components/ICP";
import NoAllow from "./components/Script";

function App() {
  return (
    <div>
      <div className="card">
        <div className="imgBx">
          <img src="/T.png" alt="头像" />
        </div>
        <div className="content">
          <div className="details">
              <h2>Y<br/> <span>ChongQing • FengJie</span></h2>
              <div className="data">
                <h3>2002<br/><span>YEAR</span></h3>
                <h3>10<br/><span>MONTH</span></h3>
                <h3>6<br/><span>DAY</span></h3>
              </div>
              <div className="actionBtn">
                <ButtonClick src="http://api.5k2.cn" text="Next"/>
                <ButtonClick src="http://wpa.qq.com/msgrd?v=3&uin=7905207&site=qq&menu=yes" text="Message"/>
              </div>
          </div>      
        </div>
      </div>
      <ICP admin="Copyright © 2024 Y" icp="备案号：渝ICP备2024027632号"/>
      <NoAllow />
    </div>
  );
}

export default App;